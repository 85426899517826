require("iframe-resizer/js/iframeResizer.min");

// ODEmbed("od-confirm-50841-iframe");
(function () {
  function postToIframe(contentWindow, command, data) {
    contentWindow.postMessage({
      from: "od_embed.js",
      command: command,
      data: data
    }, "*");
  }

  // https://stackoverflow.com/questions/10730362/get-cookie-by-name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  function postMessageReceived(event, iframe){
    var payload = event.data;

    if(typeof(payload) === "object" && payload.from === "WebOrderForm.jsx"){
      var command = payload.command;
      var data = payload.data;

      switch (command) {
        case "opendateOrderCanceled":
          iframe.scrollIntoView();
          break;
        case "opendateOrderGoToAddOns":
          iframe.scrollIntoView();
          break;
        case "opendateOrderGoToCheckout":
          iframe.scrollIntoView();
          break;
        case "opendateOrderPlaced":
          iframe.scrollIntoView();
          break;
        case "ga.page_view":
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'page_view',
            page_location: data
          });
          break;
        case "ga.add_to_cart":
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'add_to_cart',
            ecommerce: {
              items: data
            }
          });
          break;
        case "ga.begin_checkout":
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'begin_checkout',
            ecommerce: {
              items: data
            }
          });
          break;
        case "ga.purchase":
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'purchase',
            ecommerce: data
          });
          break;
        default:
          console.log("Unknown command \"" + command + "\" received from WebOrderForm.jsx");
      }
    }
  }

  if(typeof ODEmbed !== 'function'){
    window.ODEmbed = function(iframeID, options){
      var iframe = document.getElementById(iframeID);
      var contentWindow = iframe.contentWindow;

      var options = (options || {});
      var bgColor = options.bgColor;

      // Google Universal Analytics
      var clientId;
      if(typeof(ga) === "function"){
        ga(function(tracker){
          clientId = tracker.get('clientId');
        });
      }

      // Customer.io Identity
      var customerioId = getCookie("_cioid");

      // Query Parameters
      var queryString = window.location.search;
      var urlParams = new URLSearchParams(queryString);
      var goToAddOns = urlParams.get('_od_gtao');

      var wrappedPostMessageReceived = function(event){
        postMessageReceived(
          event,
          iframe
        );
      }

      // https://robertnyman.com/html5/postMessage/postMessage.html
      if (window.addEventListener) {
        window.addEventListener("message", wrappedPostMessageReceived, false);
      } else {
        window.attachEvent("onmessage", wrappedPostMessageReceived);
      }

      iframe.addEventListener("load", () => {
        if(bgColor && bgColor.length > 0){
          contentWindow.document.body.style.background = bgColor;
        }

        window['iFrameResize'](iframe);

        if(clientId && clientId.length > 0){
          postToIframe(contentWindow, "setClientID", {
            clientId: clientId
          });
        }

        if(customerioId && customerioId.length > 0){
          postToIframe(contentWindow, "setCustomerioId", {
            customerioId: customerioId
          });
        }

        if(goToAddOns === "true"){
          setTimeout(function(){
            postToIframe(contentWindow, "triggerGoToAddOns", {});
          }, 100);
        }

        postToIframe(contentWindow, "reportPageView", {});
      });
    };
  }
})();
